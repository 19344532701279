/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */



/* GENERAL */
.bold {
	font-weight: bold;
}

.mr-1 {
    margin-right: 0.5rem !important;
}
.ml-1 {
    margin-left: 0.5rem !important;
}

.mt-45 {
    margin-top: 0.45rem !important;
}

.mt-label {
    margin-top: 24px !important;
}

.show > .dropdown-menu {
	padding: 0;
}

@media (max-width: 768px) {
	.brand-logo {
		display: block;
		width: 30px;
		overflow: hidden;
	}
}



/* TABLE */
.react-dataTable .rdt_TableBody .rdt_TableRow .rdt_TableCell {
	height: auto !important;
}



/* FORM CONTROL */
.form-label {
	line-height: 24px;
	margin: 0;
}

.form-control,
.form-select {
	font-size: 12px;
	line-height: 24px;
	padding: 6px 10px;
}

textarea.form-control {
	padding: 6px 10px;
	resize: none;
}

.form-check-label.form-label {
	font-size: 0.8rem;
	line-height: 20px;
}




/* CARD */
.card .card-header h4 {
	margin: 0;

	svg {
		float: left;
		font-size: 16px;
		margin: 0 10px 0 0;
	}
}



/* MODAL */
.modal-codee {
	.modal-header {
		display: block;
		background: transparent;

		.btn-close {
			position: absolute;
			right: 1.4rem;
			top: 0.8rem;
		}
	}

	.modal-title {
		display: block;
		font-size: 28px;
		font-weight: 500;
		line-height: 34px;
		padding: 0.8rem 0;
		text-align: center;
	}

	.modal-footer {
		border: 0;

		justify-content: center;

		.btn {
			margin: 0.25rem 0.75rem;
		}
	}
}





/* EMPREENDIMENTOS */
.enterprise-thumb {
	position: relative;
	display: block;
	border-radius: 0.428rem 0.428rem 0 0;
	height: 180px;
	overflow: hidden;

	img {
		position: absolute;
		right: 50%;
		top: 50%; 
		height: 180px;
  		transform: translate(50%,-50%);
	}
}

.enterprise-title {
	display: block;
	height: 48px;
	line-height: 24px;
	margin-bottom: 1rem;
	overflow: hidden;
}



.element-thumb {
	position: relative;
	display: block;
	border-radius: 0.428rem 0.428rem 0 0;
	height: 180px;
	overflow: hidden;

	img {
		position: absolute;
		right: 50%;
		top: 50%; 
		height: 180px;
  		transform: translate(50%,-50%);
	}
}

.element-title {
	display: block;
	height: 48px;
	line-height: 24px;
	margin-bottom: 1rem;
	overflow: hidden;
}

.element-badge {
	display: inline-block;
	border-radius: 4px;
	line-height: 24px;
	margin-bottom: 1rem;
	padding: 0 10px;
}



/* PERSONALIZATION */
.personalization-thumb {
	position: relative;
	display: block;
	border-radius: 0.428rem 0.428rem 0 0;
	height: 180px;
	overflow: hidden;

	img {
		position: absolute;
		right: 50%;
		top: 50%; 
		height: 180px;
  		transform: translate(50%,-50%);
	}
}


.codee-card-personalization {
	display: block;
	min-width: 465px;

	.thumb {
		display: block;
		border-radius: 0.428rem 0.428rem 0 0;
		height: 465px;
		overflow: hidden;

		img {
			width: 100%;
		}
	}
}

.codee-details-personalization {
	display: flex;
}

@media (max-width: 768px) {
	.codee-details-personalization {
		display: block;
		text-align: right;
	}
}

.codee-image-personalization {
	display: block;
	max-width: 465px;
	margin: 0 auto 30px auto;

	img {
		width: 100%;
	}
}

.codee-input-personalization {
	display: flex;

	position: relative;
	height: 50px;
	margin: 10px 0 15px 0;
	padding: 0 0 0 80px;

	align-items: center;

	.thumb {
		position: absolute;
		left: 0; top: 0;
		width: 50px;
		height: 50px;
		overflow: hidden;
	}
}

.environment-thumb {
	position: relative;
	display: block;
	border-radius: 0.428rem;
	height: 180px;
	margin: 0 0 10px 0;
	overflow: hidden;

	img {
		position: absolute;
		right: 50%;
		top: 50%; 
		height: 180px;
  		transform: translate(50%,-50%);
	}
}



/* CODEE */
.codee-v-separator {
	display: inline-block;
	border-left: 2px solid #D8D6DE;
	height: 20px; 
	margin: 0 15px;
}

@media (max-width: 768px) {
	.codee-v-separator {
		display: block;
		border-left: 0;
		width: 100%;
		height: 5px; 
	}
}

.codee-shadow {
	box-shadow: 0 0 10px rgba(0,0,0,0.15) !important;
}


.codee-add-full {
	border-style: dashed !important;
	width: 100%;
	height: 392px;
}


.codee-steps {
	display: flex;

	width: 100%;

	align-items: center;
	justify-content: space-between;

	.step {
		display: flex;
		position: relative;
		width: auto;
		min-height: 38px;
		padding: 0 25px 0 50px;

		align-items: center;

		p {
			line-height: 18px;
			margin: 0;
			padding: 1px;
		}

		svg {
			position: absolute;
			right: 0;
		}

		.btn-icon {
			position: absolute;
			left: 0;
			top: 0;
			width: 38px;
			height: 38px;
		}
	}

	.step:last-child {
		padding: 0 0 0 50px;
	}
}

.codee-steps.left {
	display: block;

	.step {
		float: left;
		margin-right: 30px;
	}

	.step:last-child {
		margin-right: 0;
	}
}

@media (max-width: 768px) {
	.codee-steps.left {
		.step {
			clear: both;
			margin: 0 0 5px 0;
		}
	}
}


.codee-file-upload {
	background: transparent;
	border-style: dashed !important;
	box-shadow: 0 0 0;
	padding: 20px 0;
	text-align: center;

	p {
		font-size: 11px;
		margin: 0;

		span {
			text-decoration: underline;
			cursor: pointer;
		}
	}

	.or {
		position: relative;
		display: block;
		width: calc(100% - 30px);
		height: 15px;
		margin: 0 15px 15px 15px;

		span {
			position: absolute;
			left: 50%;
			top: 5px;
			font-size: 12px;
			padding: 0 5px;
  			transform: translate(-50%,0);
		}
	}
}

.dark-layout .codee-file-upload {
	.or {
		.bg-white {
			background-color: #283046 !important;
		}
	}
}


.codee-action {
	display: flex;

	height: 100%;

	align-items: flex-end;
	justify-content: flex-end;

	.btn {
		margin-left: 15px;
	}
}


.codee-actions-buttons {
	display: flex;

	justify-content: space-between;

	svg {
		margin: 0 0 0 5px;
	}
}


.codee-conclude-buttons {
	.btn {
		margin: 0 15px;
	}
}


.codee-thumb-table {
	display: block;
	border-radius: 5px;
	width: 48px;
	height: 48px;
	overflow: hidden;
}

.codee-thumb-photo {
	float: left;
	border-radius: 5px;
	width: 100px;
	height: 100px;
	margin: 0 15px 0 0;
	overflow: hidden;
}

.codee-file-buttons {
	display: flex;

	float: left;
	height: 100px;

	align-items: flex-end;

	.upload {
		position: relative;
		float: left;

		input {
			position: absolute;
			left: 0;
			bottom: 0;
			opacity: 0;
		}
	}

	.ml-15 {
		margin-left: 15px;
	}
}


.codee-videos {
	margin: 0 -15px;

	.video {
		float: left;
		border-radius: 5px;
		width: calc(20% - 30px);
		margin: 0 15px 30px 15px;
		overflow: hidden;

		.thumb {
			display: flex;

			color: #BABFC7;
			background: #F8F8F8;
			height: 100px;

			align-items: center;
			justify-content: center;
		}

		p {
			padding: 0 10px;
		}
	}
}

@media (max-width: 992px) {	
	.codee-videos {
		.video {
			width: calc(33% - 30px);
		}
	}
}

@media (max-width: 768px) {	
	.codee-videos {
		.video {
			width: calc(50% - 30px);
		}
	}
}

.react-dataTable > div { //Para correção problema botões (Editar/Excluir)
    overflow-y: unset;
    overflow-x: unset;
}
@media (max-width: 768px) { //Para correção problema botões (Editar/Excluir)
    .react-dataTable > div {
        overflow-y: hidden;
        overflow-x: auto;
    }
}

